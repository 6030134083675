import React from "react"
import kebabCase from "lodash.kebabcase"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import moment from "moment"
import { graphql, Link } from "gatsby"

const BlogCategory = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const categories = pageContext.categories
  const pages = pageContext.pages
  const location = '/category/'+pageContext.category
  const categoryTitle = pageContext.category
  const category = kebabCase(pageContext.category)
  const previousLink = (pageContext.currentPage - 1) === 1
    ? `/category/${category}`
    : `/category/${category}/page/`+(pageContext.currentPage - 1)
  const nextLink = `/category/${category}/page/`+(pageContext.currentPage + 1)

  return (
    <Layout location={location} title={categoryTitle} categories={categories} pages={pages}>
    <SEO title={categoryTitle} />
      <h1>{categoryTitle}</h1>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
          <article key={node.fields.slug}>
            <header>
            <h2
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                {title}
              </Link>
            </h2>
              <small style={{
              color: `#CACACA`
            }}>{moment(node.frontmatter.date).local().format(`MM/DD/YY`)}</small>
            </header>
            <section style={{
            paddingTop: 5
          }}>
            <div
              dangerouslySetInnerHTML={{
                __html: node.excerpt
              }}
            />
          </section>
          </article>
        )
      })}

      <ul>
          <span style={{float:'right'}}>{pageContext.currentPage < pageContext.numPages ? (<a href={nextLink}>Older Posts &gt;</a>) : ''}</span>
          {pageContext.currentPage > 1 ? (<a href={previousLink}>&lt; Newer Posts</a>) : ''}&nbsp;  
      </ul>
    </Layout>
  )
}

export default BlogCategory

export const query = graphql`
  query blogPostsListByCategory($category: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { date: { ne: null }, categories: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories
          }
        }
      }
    }
  }
`