import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogPageTemplate extends React.Component {


  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { categories, pages } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle} categories={categories} pages={pages}>
        <SEO
          title={post.frontmatter.title}
          
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 10,
              }}
            >
              {post.frontmatter.title}
            </h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
