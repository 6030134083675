import React from "react"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash.kebabcase"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import moment from "moment"

class BlogPostTemplate extends React.Component {


  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, categories, pages } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle} categories={categories} pages={pages}>
        <SEO
          title={post.frontmatter.title}
          
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 10,
              }}
            >
              {post.frontmatter.title}
            </h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
                color: `#CACACA`
              }}
            >
              Posted on {moment(post.frontmatter.date).local().format(`MM/DD/YY`)} | {post.frontmatter.categories.map(cat => {
                return (<Link to={`/category/${kebabCase(cat)}`} style={{marginRight: `5px`}}>{cat}</Link>)
              })}
            </p>
          </footer>
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
            class="articleNav"
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        categories
      }
    }
  }
`
