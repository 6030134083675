/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const BlogHeader = ({pages}) => {
  const data = useStaticQuery(graphql`
    query BlogHeaderQuery {
      image: file(absolutePath: { regex: "/blogheader.jpg/" }) {
        childImageSharp {
          fixed(width: 940, height: 198) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header
      style={{
        marginBottom: rhythm(2.5),
        width: 1000,
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      <Link to={'/'}><Image
      style={{width: '100%'}}
        fixed={data.image.childImageSharp.fixed}
      /></Link>
      <div style={{marginTop: '-8px', display: 'block', backgroundColor: 'black', float: 'left', clear: 'both', width:'100%'}}>
        <ul>
          {pages.map(({ slug, title }) => {
              return (<li key={slug}><Link to={slug}>{title}</Link></li>)
          })}
        </ul>
      </div>
    </header>
  )
}

export default BlogHeader
