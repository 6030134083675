import React from "react"
import { Link } from "gatsby"
import  BlogHeader from "./header"
import kebabCase from "lodash.kebabcase"

import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const {categories, pages, children } = this.props
  
    //padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
//    if (location.pathname === rootPath) {
    return (
      <>
      <BlogHeader pages={pages}/>
      
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `900px`,
          padding: `0 ${rhythm(3 / 4)}`,
        }}
      >
        <div style={{float:`right`,width: `150px`, paddingLeft: `10px`}}>
        <ul>{categories.map(cat => (
          <li style={{
            fontSize: '10pt',
            marginBottom: '0px'
          }}><Link to={`/category/${kebabCase(cat)}`}>{cat}</Link></li>
        ))}</ul>
    
        </div>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()} JeanCarl Bisson
        </footer>
      </div>
      </>
    )
  }
}

export default Layout
