import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import moment from "moment"
import { graphql, Link } from "gatsby"

const BlogPostList = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const categories = pageContext.categories
  const pages = pageContext.pages
  const location = '/'
  const categoryTitle = 'All Posts'
  const previousLink = (pageContext.currentPage - 1) === 1
  ? `/`
  : `/page/`+(pageContext.currentPage - 1)
  const nextLink = `/page/`+(pageContext.currentPage + 1)

  return (
    <Layout location={location} title={categoryTitle} categories={categories} pages={pages}>
    <SEO title="All posts" />
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <article key={node.fields.slug}>
          <header>
            <h2
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                {title}
              </Link>
            </h2>
            <small style={{
              color: `#CACACA`
            }}>{moment(node.frontmatter.date).local().format(`MM/DD/YY`)} | {node.frontmatter.categories.join(', ')}</small>
          </header>
          <section style={{
            paddingTop: 5
          }}>
            <div
              dangerouslySetInnerHTML={{
                __html: node.excerpt,
              }}
            />
          </section>
        </article>
      )
    })}

    <ul>
          <span style={{float:'right'}}>{pageContext.currentPage < pageContext.numPages ? (<a href={nextLink}>Older Posts &gt;</a>) : ''}</span>
          {pageContext.currentPage > 1 ? (<a href={previousLink}>&lt; Newer Posts</a>) : ''}&nbsp;  
      </ul>
  </Layout>
  )
}

export default BlogPostList

export const query = graphql`
  query blogPostsList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { date: { ne: null }}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories
          }
        }
      }
    }
  }
`